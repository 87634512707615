import React, { useEffect, useState } from "react";
import Fuse from "fuse.js";

import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { Table, Space, Button, Input, Spin, Tooltip, Switch } from "antd";
import { EditOutlined, LockOutlined, CopyOutlined } from "@ant-design/icons";
import {
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import {
  useDeleteParticularUserMutation,
  useGetNewUserQuery,
  useToggleUserMutation,
} from "../../../redux/api/createUserApi";
import Header from "../../../components/Header";
import NewCreateUser from "../CreateUser";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import "./UserList.css";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Relationship Manager",
    dataIndex: "relationship_manager",
    key: "relationship_manager",
    render: (relationship_manager) => relationship_manager?.[0]?.username,
  },
  {
    title: "Access Type",
    dataIndex: "access_type",
    key: "access_type",
  },
  {
    title: "Company Name",
    dataIndex: "company_name",
    key: "company_name",
  },
  {
    title: "Action",
    key: "action",
  },
];

const UserList = () => {
  const [searchString, setSearchString] = useState("");
  const [result, setResult] = useState([]);
  const isNavBarShrink = useSelector((store) => store.interface.isNavBarShrink);
  // const { data: userData, error, isLoading, isFetching } = useGetUserApiQuery();
  const { data: userData, error, isLoading, isFetching } = useGetNewUserQuery();
  const [triggerDeleteUser, { data: deleteUserData, error: deleteError }] =
    useDeleteParticularUserMutation();
  const [
    toggleUser,
    {
      data: toggleUserData,
      isLoading: toggleUserLoading,
      isSuccess: toggleUserSucess,
      isError: toggleUserFailed,
    },
  ] = useToggleUserMutation();
  const navigate = useNavigate();
  let fuse;
  const [open, setOpen] = useState(false);
  const [dialogUserId, setDialogUserId] = useState({});
  // const [isEdit, setIsEdit] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [showCustomUsers, setShowCustomUsers] = useState(false);

  const handleSwitchChange = (checked) => {
    setShowCustomUsers(checked);
    if (!checked) {
      if (searchString === "") {
        setResult(
          [...userData?.data].map((user) => {
            return {
              item: user,
            };
          })
        );
      }
      // setResult(
      //   [...userData?.data].map((user) => {
      //     return {
      //       item: user,
      //     };
      //   })
      // );
      else {
        handleSearch(searchString);
      }
    } else {
      if (searchString === "") {
        setResult(
          [...userData?.data]
            .filter((user) => user.custom_user)
            .map((user) => {
              return {
                item: user,
              };
            })
        );
      } else {
        handleSearch(searchString, true);
      }
    }
  };

  const copyUserId = (record) => {
    navigator.clipboard.writeText(record._id);
    toast.success("User ID copied to clipboard");
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email, record) => (
        <div>
          {email}
          {record.custom_user && (
            <span
              style={{
                marginLeft: "8px",
                color: "white",
                backgroundColor: "#7bc0ee",
                padding: "2px 6px",
                // borderRadius: "5px",
                borderRadius: "10px",
              }}
            >
              Custom User
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Relationship Manager",
      dataIndex: "relationship_manager",
      key: "relationship_manager",
      render: (relationship_manager) => relationship_manager?.[0]?.username,
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD "),
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit User">
            <Button
              type="primary"
              ghost
              style={{ background: "white" }}
              icon={<EditOutlined />}
              onClick={() => navigateToEditUser(record)}
            />
          </Tooltip>
          <Tooltip title={record.active ? "Deactivate User" : "Activate User"}>
            <Button
              danger={record.active}
              onClick={() => {
                toggleUser({
                  email: record.email,
                });
              }}
              style={{
                width: "100px",
                borderColor: record.active ? "" : "green", // Apply green border only when record.active is false
                color: record.active ? "" : "green", // Apply green text color only when record.active is false
                backgroundColor: record.active ? "" : "white", // Transparent background only when record.active is false
              }}
            >
              {record.active ? "Deactivate" : "Activate"}
            </Button>
          </Tooltip>
          <Tooltip title="Edit User Subscriptions">
            <Button
              style={{
                borderColor: "black",
                color: "black",
                backgroundColor: "white",
              }}
              icon={<LockOutlined />}
              onClick={() => {
                navigate(`/home/user-management/report-assignment`, {
                  state: {
                    userEmail: record.email,
                    userId: record._id,
                    userName: record.name,
                    semiAccess: record?.semi_access || false,
                    customUser: record?.custom_user || false,
                  },
                });
                // Add your manage access logic here
              }}
            >
              Manage Report Access
            </Button>
          </Tooltip>

          <Tooltip title="Copy user id">
            <Button
              type="primary"
              ghost
              style={{ background: "white" }}
              icon={<CopyOutlined />}
              onClick={() => copyUserId(record)}
            />
          </Tooltip>

        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (userData) {
      if (showCustomUsers) {
        let newResult = [...userData?.data]
          .filter((user) => user.custom_user)
          .map((user) => {
            return {
              item: user,
            };
          });
        setResult(newResult);
        return;
      }

      let newResult = [...userData?.data].map((user) => {
        return {
          item: user,
        };
      });
      setResult(newResult);
    }
  }, [userData]);

  useEffect(() => {
    if (toggleUserSucess) {
      setSearchString("");
      toast.success(toggleUserData?.message);
    }
    if (toggleUserFailed) {
      toast.error(toggleUserData?.message ?? "Something went wrong");
    }
  }, [toggleUserSucess, toggleUserFailed]);

  const navigateToEditUser = (userData) => {
    // Navigate to edit user page
    setEditUserId(userData);
    // navigate(`/home/user-management/update-user`, { state: { userData } });
  };
  const handleDeleteUser = async (userId) => {
    // Delete user
    const res = await triggerDeleteUser({ userId: dialogUserId?.userId });
    setOpen(false);
  };

  const handleSearch = (value, customUser = false) => {
    value = typeof value === "string" ? value.trim() : searchString.trim();
    let searchResult;
    if (value === "") {
      searchResult = [...userData?.data].map((user) => {
        return {
          item: user,
        };
      });
      if (customUser) {
        searchResult = searchResult.filter((user) => user?.item?.custom_user);
      }
    } else {
      const options = {
        includeScore: false,
        minMatchCharLength: 3,
        keys: ["name", "email"],
      };
      fuse = new Fuse([...userData?.data], options);
      searchResult = fuse.search(value);
      if (customUser) {
        searchResult = searchResult.filter((user) => user?.item?.custom_user);
      }
    }
    setResult(searchResult);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchString(value);
    debouncedSearch(value, showCustomUsers);
  };

  const debouncedSearch = debounce(handleSearch, 300);
  const handleClose = () => {
    setOpen(false);
  };
  const setToSearchScreen = () => {
    setEditUserId(null);
  };
  return (
    <>
      {editUserId ? (
        <NewCreateUser
          userData={editUserId}
          setToSearchScreen={setToSearchScreen}
        />
      ) : (
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`${
                dialogUserId.disable ? "Disable" : "Enable"
              } User Confirmation`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to <b>{dialogUserId.name}</b>{" "}
                {dialogUserId.disable ? "disable" : "enable"} this user?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              <Button onClick={handleDeleteUser} autoFocus>
                {dialogUserId.disable ? "Disable" : "Enable"}
              </Button>
            </DialogActions>
          </Dialog>
          {isLoading || isFetching || toggleUserLoading ? (
            <Spin spinning={isLoading || isFetching} fullscreen />
          ) : (
            <>
              <Grid item sm={12}>
                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "30px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ marginTop: "5px" }}>User Type: </span>
                    <Switch
                      checked={showCustomUsers}
                      onChange={handleSwitchChange}
                      checkedChildren="Custom Users"
                      unCheckedChildren="All Users"
                      style={{
                        marginTop: "5px",
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <Input
                    style={{ width: "20%", border: "1px solid #67bfff" }}
                    placeholder="Search Users by Name or Email"
                    value={searchString}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch(e.target.value, showCustomUsers);
                      }
                    }}
                    // suffix={
                    //   <Button
                    //     icon={<SearchOutlined />}
                    //     onClick={handleSearch}
                    //   />
                    // }
                  />
                </div>
              </Grid>
              <Table
                className="custom-table-style"
                style={{ border: "1px solid lightgrey", borderRadius: "10px" }}
                columns={columns}
                dataSource={result?.map((user) => user?.item)}
                rowKey="_id"
              />
              {/* <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Relationship Manager</TableCell>
                  <TableCell>Access Type</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result?.map((user) => (
                  <TableRow
                    sx={{
                      backgroundColor: user?.item?.active ? "white" : "#f5f5f5",
                    }}
                    key={user?.item._id}
                  >
                    <TableCell>{user?.item.name}</TableCell>
                    <TableCell>{user?.item.email}</TableCell>
                    <TableCell>
                      {user?.item?.relationship_manager?.[0]?.username}
                    </TableCell>
                    <TableCell>{user?.item.access_type}</TableCell>
                    <TableCell>{user?.item.company_name}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => navigateToEditUser(user?.item)}
                      >
                        <EditIcon />
                      </IconButton>
                      {user?.item?.active ? (
                        <IconButton
                          onClick={() => {
                            setDialogUserId({
                              userId: user?.item._id,
                              disable: true,
                              name: user?.item.name,
                            });
                            setOpen(true);
                          }}
                          aria-label="Disable User"
                          title="Disable User"
                        >
                          <NoAccounts />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setDialogUserId({
                              userId: user?.item._id,
                              disable: false,
                              name: user?.item.name,
                            });
                            setOpen(true);
                          }}
                          aria-label="Enable User"
                          title="Enable User"
                        >
                          <PersonAddIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default UserList;
